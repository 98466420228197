<template>
  <div>
    <base-header :style="{ background: '#F7F8FD' }" class="py-6">
      <div class="row"></div>
    </base-header>

    <div class="container-fluid">
      <v-app>
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-6">
              <h2 class="m-0">Staff/Order Report</h2>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <download-excel
                :data="filteredData"
                :fields="filteredHeaders"
                title="Staff Report"
                name="staff-report"
              >
                <base-button
                  icon="fa fa-file-excel"
                  class="border-0 text-white bg-dark"
                  style="backgroundcolor: black"
                  size="md"
                >
                </base-button>
              </download-excel>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-md">
              <v-autocomplete
                deletable-chips
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                small-chips
                multiple
                return-object
                flat
                clearable
                item-text="username"
                v-model="searchData.staffs"
                label="Staff"
                :items="staffList"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < maxDisplay" @click="data.select">
                    {{ item.username }} &nbsp;
                  </v-chip>

                  <span v-if="index === maxDisplay" class="grey--text caption"
                    >(+{{ searchData.staffs.length - maxDisplay }} staffs)</span
                  >
                </template>
                <v-list-tile
                  class="row-pointer p-3"
                  slot="prepend-item"
                  ripple
                  @click="toggleStaffSelect"
                >
                  <v-list-tile-action class="mr-2">
                    <v-icon
                      :color="
                        searchData.staffs.length > 0 ? 'indigo darken-4' : ''
                      "
                      >{{ iconStaff }}</v-icon
                    >
                  </v-list-tile-action>
                  <v-list-tile-title class="ml-5 mt-3"
                    >Select All</v-list-tile-title
                  >
                </v-list-tile>
                <v-divider slot="prepend-item" class="mt-2" />
              </v-autocomplete>
            </div>
            <div class="col-md">
              <v-autocomplete
                deletable-chips
                multiple
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                small-chips
                clearable
                return-object
                item-text="name"
                label="Distributor"
                v-model="searchData.distributors"
                :items="distributorsList"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < maxDisplay" @click="data.select">
                    {{ item.name }} &nbsp;
                  </v-chip>

                  <span v-if="index === maxDisplay" class="grey--text caption"
                    >(+{{
                      searchData.distributors.length - maxDisplay
                    }}
                    distributors)</span
                  >
                </template>
                <v-list-tile
                  class="row-pointer p-3"
                  slot="prepend-item"
                  ripple
                  @click="toggleDistSelect"
                >
                  <v-list-tile-action class="mr-2">
                    <v-icon
                      :color="
                        searchData.distributors.length > 0
                          ? 'indigo darken-4'
                          : ''
                      "
                      >{{ iconDist }}</v-icon
                    >
                  </v-list-tile-action>
                  <v-list-tile-title class="ml-5 mt-3"
                    >Select All</v-list-tile-title
                  >
                </v-list-tile>
                <v-divider slot="prepend-item" class="mt-2" />
              </v-autocomplete>
            </div>

            <div class="col-md">
              <v-autocomplete
                deletable-chips
                multiple
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                small-chips
                return-object
                clearable
                item-text="name"
                v-model="searchData.route"
                label="Routes"
                :items="routeList"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < maxDisplay" @click="data.select">
                    {{ item.name }} &nbsp;
                  </v-chip>

                  <span v-if="index === maxDisplay" class="grey--text caption"
                    >(+{{ searchData.route.length - maxDisplay }} routes)</span
                  >
                </template>
                <v-list-tile
                  class="row-pointer p-3"
                  slot="prepend-item"
                  ripple
                  @click="toggleRouteSelect"
                >
                  <v-list-tile-action class="mr-2">
                    <v-icon
                      :color="
                        searchData.route.length > 0 ? 'indigo darken-4' : ''
                      "
                      >{{ iconRoute }}</v-icon
                    >
                  </v-list-tile-action>
                  <v-list-tile-title class="ml-5 mt-3"
                    >Select All</v-list-tile-title
                  >
                </v-list-tile>
                <v-divider slot="prepend-item" class="mt-2"
              /></v-autocomplete>
            </div>
          </div>
          <div class="row mt-0 mt-md-4">
            <div class="col-md mt-0 mt-md-4">
              <v-text-field
                class="mt-0 mt-md-4"
                label="From Date"
                v-model="searchData.from_date"
                type="date"
                fab
              ></v-text-field>
            </div>
            <div class="col-md mt-0 mt-md-4">
              <v-text-field
                class="mt-0 mt-md-4"
                label="To Date"
                v-model="searchData.to_date"
                type="date"
                fab
              ></v-text-field>
            </div>
            <div class="col-md">
              <label class="form-control-label">Apply filters</label>
              <base-button
                @click="filterReport"
                type="test"
                class="w-100"
                outline
                >Search</base-button
              >
            </div>
            <!-- <div class="col-md mt-4 mt-md-0">
              <label class="form-control-label">Clear filters</label>
              <base-button type="base" class="w-100" outline>Clear</base-button>
            </div> -->
          </div>

          <div v-if="data.length > 0" class="table-responsive mt-4">
            <base-table
              class="align-items-center"
              thead-classes="thead-light"
              type="hover"
              :data="data"
            >
              <template slot="columns">
                <th>Sl</th>
                <th v-for="(header, index) in headers" :key="index">
                  {{ header }}
                </th>
              </template>
              <template slot-scope="{ row, index }">
                <td>{{ index + 1 }}</td>
                <td>{{ row.user.employee_code }}</td>
                <td>{{ row.user.username }}</td>
                <td>{{ row.shop.place }}</td>
                <td>{{ row.shop.name }}</td>
                <td>{{ row.shop.mobile }}</td>
                <td>{{ row.date }}</td>
                <td v-for="(header, idx) in prodHeaders" :key="idx">
                  {{ data[index][header] }}
                </td>
                <td>{{ row.amount }}</td>
              </template>
            </base-table>
          </div>
          <div v-else class="d-flex justify-content-center">
            <lottie-player
              src="https://assets1.lottiefiles.com/packages/lf20_lwnuxmxm.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 300px"
              loop
              autoplay
            ></lottie-player>
          </div>
        </card>
      </v-app>
    </div>
  </div>
</template>
<script>
export default {
  name: "staff-report",
  data() {
    return {
      tableHeaders: [
        "Employee Code",
        "Staff",
        "Location",
        "Shop Name",
        "Shop Mobile",
        "Date",
        "Products",
        "Count",
        "Total Amount",
      ],
      maxDisplay: 2,
      headers: ["Location", "Shop Name", "Date"],
      prodHeaders: [],
      data: [],
      searchData: {
        staffs: [],
        distributors: [],
        route: [],
        from_date: null,
        to_date: null,
      },
      filteredData: [],
      filteredHeaders: [],
    };
  },
  methods: {
    filterReport() {
      let routes = this.searchData.route.map((item) => item.id);
      let distributors = this.searchData.distributors.map((item) => item.id);
      let staffs = this.searchData.staffs.map((item) => item.id);

      let error = undefined;

      if (staffs.length === 0) {
        error = "Please select a user";
      }

      if (this.searchData.from_date === "") {
        this.searchData.from_date = null;
      }

      if (this.searchData.to_date === "") {
        this.searchData.to_date = null;
      }

      let payload = {};

      if (
        this.searchData.from_date === null ||
        this.searchData.end_date === null
      ) {
        payload = {
          staffs: staffs,
          distributors: distributors,
          route: routes,
        };
      } else {
        payload = {
          staffs: staffs,
          distributors: distributors,
          route: routes,
          from_date: this.searchData.from_date,
          to_date: this.searchData.to_date,
        };
      }

      if (error) {
        this.$notify({
          title: error,
          icon: "fa fa-exclamation-triangle",
          type: "danger",
        });
      } else {
        this.$store
          .dispatch("fetchStaffReport", {
            payload,
          })
          .catch((err) => {
            this.$notify({
              title: "Failed to fetch staff report",
              icon: "fa fa-exclamation-triangle",
              type: "danger",
              message: err.toString(),
            });
          });
      }
    },
    fetchStaffList() {
      this.$store.dispatch("fetchStaffList").catch((err) => {
        this.$notify({
          title: "Failed to fetch staff list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    fetchDistributorList() {
      this.$store.dispatch("fetchDistributorList").catch((err) => {
        this.$notify({
          title: "Failed to fetch distributors list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    fetchRouteList() {
      this.$store.dispatch("fetchRouteList").catch((err) => {
        this.$notify({
          title: "Failed to fetch route list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    toggleStaffSelect() {
      this.$nextTick(() => {
        if (this.selectAllStaff) {
          this.searchData.staffs = [];
        } else {
          this.searchData.staffs = this.staffList.slice();
        }
      });
    },
    toggleDistSelect() {
      this.$nextTick(() => {
        if (this.selectAllDist) {
          this.searchData.distributors = [];
        } else {
          this.searchData.distributors = this.distributorsList.slice();
        }
      });
    },
    toggleRouteSelect() {
      this.$nextTick(() => {
        if (this.selectAllRoute) {
          this.searchData.route = [];
        } else {
          this.searchData.route = this.routeList.slice();
        }
      });
    },
  },
  computed: {
    staffList() {
      return this.$store.state.staffStore.staffs;
    },
    distributorsList() {
      return this.$store.state.distributorStore.distributors;
    },
    routeList() {
      return this.$store.state.routeStore.routes;
    },
    staffReport() {
      return this.$store.state.reportStore.staffReport;
    },
    selectAllStaff() {
      return this.searchData.staffs.length === this.staffList.length;
    },
    selectSomeStaff() {
      return this.searchData.staffs.length > 0 && !this.selectAllStaff;
    },
    iconStaff() {
      if (this.selectAllStaff) return "mdi-close-box";
      if (this.selectSomeStaff) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    labelStaff() {
      if (this.searchData.staffs.length < this.staffList) {
        return "";
      }
      return this.searchData.staffs.length === this.staffList
        ? "All Selected"
        : "";
    },
    selectAllDist() {
      return (
        this.searchData.distributors.length === this.distributorsList.length
      );
    },
    selectSomeDist() {
      return this.searchData.distributors.length > 0 && !this.selectAllDist;
    },
    iconDist() {
      if (this.selectAllDist) return "mdi-close-box";
      if (this.selectSomeDist) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllRoute() {
      return this.searchData.route.length === this.routeList.length;
    },
    selectSomeRoute() {
      return this.searchData.route.length > 0 && !this.selectAllRoute;
    },
    iconRoute() {
      if (this.selectAllRoute) return "mdi-close-box";
      if (this.selectSomeRoute) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    staffReport() {
      let products = [];

      this.staffReport.forEach((item) => {
        item.products.forEach((prod) => products.push(prod.product_name));
      });

      let filteredProd = [...new Set(products)];
      let report = [...this.staffReport];

      filteredProd.forEach((item) => {
        report.forEach((order, idx) => {
          order.products.forEach((prod) => {
            if (prod.product_name === item) {
              let productprice =
                Number(prod.total_price) / Number(prod.quantity);
              report[idx][
                prod.product_name
              ] = `${prod.quantity} x ${productprice} = ${prod.total_price}`;
            }
          });
        });
      });

      this.headers = [
        "Employee Code",
        "Staff",
        "Location",
        "Shop Name",
        "Shop Mobile",
        "Date",
      ];
      this.headers = [...this.headers, ...filteredProd, "Total Amount"];
      this.prodHeaders = [...filteredProd];
      this.data = [...report];
      console.log("this is data", this.data);
      let tempdata = [...report];

      tempdata = tempdata.map((item) => ({
        employee_code: item.user.employee_code,
        staff: item.user.username,
        shop_place: item.shop.place,
        shop_name: item.shop.name,
        shop_mobile: item.shop.mobile,
        date: item.date,
        ...item,
      }));

      tempdata.forEach((item) => {
        delete item.products;
        delete item.edited_date;
        delete item.shop
        delete item.id;
        delete item.user;
      });

      console.log("data", tempdata);
      this.filteredData = tempdata;

      let data1 = [];
      tempdata.forEach((item) => {
        data1.push(...Object.keys(item));
      });
      let data2 = [...new Set(data1)];
      let data3 = data2.map((e) => [e, e]);
      data3 = Object.fromEntries(data3);
      data3.total_amount = data3.amount;
      delete data3.amount;
      this.filteredHeaders = data3;
    },
  },
  mounted() {
    this.fetchStaffList();
    this.fetchRouteList();
    this.fetchDistributorList();
  },
};
</script>
<style>
.row-pointer :hover {
  cursor: pointer;
}
</style>
