<template>
  <div class="jumbo-page">
    <main class="admin-main">
      <div
        class="loader-wrapper"
        :class="{ 'd-flex': displayLoader }"
        id="loader-wrapper"
      >
        <img src="../../assets/loader.svg" alt="loader" />
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-5 bg-white">
            <div class="row align-items-center min-vh-100">
              <div class="mx-auto col-sm-8">
                <div class="p-2 text-center">
                  <p>
                    <!-- <img src="/img/brand/green.png" width="80" alt="Company Logo"> -->
                  </p>
                  <p class="admin-brand-content">TEVHO</p>
                </div>
                <h3 class="text-center p-2 pb-4 fw-400 text-uppercase">
                  Admin Login
                </h3>
                <div
                  class="alert alert-danger"
                  :class="{ 'd-none': !showErrorMessage }"
                  role="alert"
                >
                  {{ errorMessage }}
                </div>
                <div
                  class="alert alert-warning"
                  :class="{
                    'd-none': !showCheckEmailMessage,
                  }"
                  role="alert"
                >
                  Check your email for instructions to reset your password
                </div>
                <form
                  class="needs-validation"
                  id="loginform"
                  method="POST"
                  @submit.prevent="frmSubmit"
                >
                  <div class="form-row">
                    <v-text-field
                      class="col-10"
                      v-model="email"
                      type="email"
                      label="Email"
                    ></v-text-field>
                    <v-text-field
                      class="col-10"
                      v-model="password"
                      type="password"
                      label="Password"
                    ></v-text-field>
                    <!-- </div> -->
                  </div>

                  <!-- <button
										type="submit"
										class="btn btn-block btn-lg mt-3"
										id="login-button"
										:style="{
											background: '#004785',
											color: 'white',
										}"
									>
										Login
									</button> -->
                  <v-btn
                    class="mt-3 col-12"
                    large
                    color="blue-grey"
                    type="submit"
                    :style="{
                      background: 'black',
                      color: 'white',
                    }"
                  >
                    Login
                  </v-btn>
                </form>
                <div class="text-right pt-1">
                  <!-- <span @click.prevent="toggleResetPasswordDialog" class="text-underline small-font">Forgot Password?</span> -->

                  <modal
                    :show="showResetPasswordDialog"
                    type="notice"
                    @close="toggleResetPasswordDialog"
                  >
                    <template v-slot:header> Reset password </template>
                    <fieldset>
                      <div class="pb-3 pt-2 pl-5 pr-5 text-center">
                        Type in your email ID to reset your password
                      </div>
                      <input
                        class="form-control input-lg"
                        placeholder="Email ID"
                        v-model="resetemail"
                        id="email-textbox"
                        name="email"
                        type="email"
                      />
                    </fieldset>
                    <template v-slot:footer>
                      <button
                        class="btn btn-success"
                        @click.prevent="resetPassword"
                      >
                        Reset password
                      </button>
                    </template>
                  </modal>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-7 d-none d-md-block bg-cover image-box"></div>
        </div>
      </div>
    </main>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      showErrorMessage: false,
      errorMessage: "",

      showResetPasswordDialog: false,
      showCheckEmailMessage: false,
      displayLoader: false,

      email: "",
      password: "",
      resetemail: "",
      image: "../assets/4.jpg",
    };
  },
  methods: {
    toggleResetPasswordDialog() {
      this.resetemail = "";
      this.showResetPasswordDialog = !this.showResetPasswordDialog;
    },
    showError(error) {
      this.errorMessage = error;
      this.showErrorMessage = true;
    },
    resetPassword() {
      if (this.resetemail.trim() == "") {
        this.toggleResetPasswordDialog();
        return;
      }

      this.displayLoader = true;
      this.showErrorMessage = false;
      this.showCheckEmailMessage = false;

      setTimeout(() => {
        this.displayLoader = false;
        this.showCheckEmailMessage = true;
        this.toggleResetPasswordDialog();
        this.$notify({
          title: "Error",
          icon: "fa fa-exclamation-circle",
          type: "warning",
          message: "Backend API unavailable",
        });
      }, 500);
    },
    async frmSubmit() {
      this.displayLoader = true;
      this.showCheckEmailMessage = false;
      this.showErrorMessage = false;
      const { email, password } = this;
      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          this.$router.push(
            this.$route.query.redirect || this.userType === "admin"
              ? "/products"
              : "staff-management"
          );
        })
        .catch((error) => {
          this.displayLoader = false;
          this.showError(error);
        });
      this.displayLoader = false;
      return false;
    },
  },
  computed: {
    userType() {
      return localStorage.getItem("user-type");
    },
  },
};
</script>
<style scoped>
.text-underline {
  text-decoration: underline;
  cursor: pointer;
}

.small-font {
  font-size: 14px;
}

.image-box {
  background-image: url("https://tevho.com/assets/uploads/media-uploader/tevhoima1642685737.png");
  background-size: cover;
  /* object-fit: none; */
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* background-attachment: fixed; */
  /* background-position: center; */
  background-repeat: no-repeat;
}
</style>
