<template v-slot:extension>
  <div>
    <base-header :style="{ background: '#F7F8FD' }" class="py-6">
      <div class="row"></div>
    </base-header>
    <div class="container-fluid">
      <card>
        <v-toolbar flat color="black" dark>
          <v-toolbar-title>Messages</v-toolbar-title>
        </v-toolbar>
        <tabs fill class="px-4 mt-5 pb-4 flex-column flex-md-row" v-model="tab">
          <tab-pane
            v-for="(tab, index) in groupedData"
            :key="index"
            :title="Object.keys(tab)[0]"
          >
            <div v-if="tab[Object.keys(tab)[0]].length > 0">
              <card
                v-for="(msg, i) in tab[Object.keys(tab)[0]]"
                :key="i"
                class="my-3 shadow-md text-black w-100"
                light
                max-width="800"
              >
                <h4 class="text-end d-flex justify-contend-end flex-end">
                  {{ msg.message }}
                </h4>
                <h5 class="text-muted">
                  {{ msg.date.slice(0, 10) }}
                </h5>
              </card>
              <card
                class="my-3 shadow-md text-black w-100"
                light
                max-width="800"
              >
                <textarea
                  class="form-control form-control-lg"
                  type="textarea"
                  rows="3"
                  v-model="message"
                  placeholder="Enter text"
                />
                <div class="d-flex justify-content-end align-items-center">
                  <button
                    class="btn btn-sm mt-2 text-white"
                    :style="{ background: 'black' }"
                    @click="sendMessage(tab)"
                  >
                    Send
                  </button>
                </div>
              </card>
            </div>
          </tab-pane>
        </tabs>
      </card>
    </div>
  </div>
</template>
<script>
export default {
  name: "Messages",
  data() {
    return {
      staffList: [],
      groupedData: [],
      tab: 1,
      items: [],
      message: "",
    };
  },
  methods: {
    fetchNotificationList() {
      this.$store.dispatch("fetchNotificationList").catch((err) => {
        this.$notify({
          title: "Failed to fetch messages",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    sendMessage(tab) {
      let ID = tab[Object.keys(tab)[0]][0].user;
      let error = undefined;

      if (ID === undefined) {
        error = "Invalid user";
      } else if (this.message.trim() === "") {
        error = "Message cannot be empty";
      }

      if (error) {
        this.$notify({
          title: error,
          icon: "fa fa-exclamation-triangle",
          type: "danger",
        });
      } else {
        let payload = {
          message: this.message,
          status: "pending",
          user: ID,
        };

        this.$store
          .dispatch("setNotification", {
            payload,
          })
          .then(() => {
            this.$notify({
              title: "Success",
              icon: "fa fa-check-circle",
              type: "success",
              message: "Message send successfully",
            });
            this.tab = 0;
            this.fetchNotificationList();
            this.message = "";
          })
          .catch((err) => {
            this.$notify({
              title: "Failed to send message",
              icon: "fa fa-exclamation-triangle",
              type: "danger",
              message: err.toString(),
            });
          });
      }
    },
  },
  computed: {
    notificationList() {
      return this.$store.state.notificationStore.notifications;
    },
  },
  watch: {
    notificationList() {
      let staffNames = this.notificationList.map((item) => item.user_name);
      let filteredNames = [...new Set(staffNames)];
      this.staffList = [...filteredNames];

      let result = this.notificationList.reduce(function (r, a) {
        r[a.user_name] = r[a.user_name] || [];
        r[a.user_name].push(a);
        return r;
      }, Object.create(null));

      console.log(result);

      const result2 = Object.entries(result).map((e) => ({ [e[0]]: e[1] }));

      this.groupedData = [...result2];
    },
    tab() {
      console.log(this.tab);
    },
  },
  mounted() {
    this.fetchNotificationList();
  },
};
</script>
<style scoped></style>
