<template>
  <div>
    <base-header :style="{ background: '#F7F8FD' }" class="py-6">
      <div class="row"></div>
    </base-header>

    <div class="container-fluid">
      <v-app>
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-6">
              <h2 class="m-0">ASM Report</h2>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <download-excel
                :data="filteredData"
                :fields="filteredHeaders"
                title="ASM Report"
              >
                <base-button
                  icon="fa fa-file-excel"
                  class="border-0 text-white bg-dark"
                  style="backgroundcolor: black"
                  size="md"
                >
                </base-button>
              </download-excel>
            </div>
          </div>

          <div class="row align-items-center">
            <div class="col-md">
              <v-autocomplete
                deletable-chips
                multiple
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                small-chips
                return-object
                clearable
                item-text="name"
                v-model="searchData.asm"
                label="ASM"
                :items="asmList"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < maxDisplay" @click="asmList.select">
                    {{ item.name }} &nbsp;
                  </v-chip>

                  <span v-if="index === maxDisplay" class="grey--text caption"
                    >(+{{ searchData.asm.length - maxDisplay }} asm)</span
                  >
                </template>
                <v-list-tile
                  class="row-pointer p-3"
                  slot="prepend-item"
                  ripple
                  @click="toggleASMSelect"
                >
                  <v-list-tile-action class="mr-2">
                    <v-icon
                      :color="
                        searchData.asm.length > 0 ? 'indigo darken-4' : ''
                      "
                      >{{ iconASM }}</v-icon
                    >
                  </v-list-tile-action>
                  <v-list-tile-title class="ml-5 mt-3"
                    >Select All</v-list-tile-title
                  >
                </v-list-tile>
                <v-divider slot="prepend-item" class="mt-2"
              /></v-autocomplete>
            </div>
            <div class="col-md">
              <v-text-field
                class=""
                type="date"
                v-model="searchData.from_date"
                label="Start Date"
                fab
              ></v-text-field>
            </div>
            <div class="col-md">
              <v-text-field
                class=""
                type="date"
                v-model="searchData.to_date"
                label="End Date"
                fab
              ></v-text-field>
            </div>
            <div class="col-md mb-9">
              <label class="form-control-label">Apply filters</label>
              <base-button
                @click="filterReport"
                type="test"
                class="w-100"
                outline
                >Search</base-button
              >
            </div>
          </div>

          <div v-if="asmReport.length > 0" class="table-responsive mt-4">
            <base-table
              class="align-items-center"
              thead-classes="thead-light"
              type="hover"
              :data="asmReport"
            >
              <template slot="columns">
                <th>Sl</th>
                <th v-for="(header, index) in headers" :key="index">
                  {{ header }}
                </th>
              </template>
              <template slot-scope="{ row, index }">
                <td>{{ index + 1 }}</td>
                <td>{{ row.user.username }}</td>
                <td>{{ row.shop.name }}</td>
                <td>{{ row.shop.place }}</td>
                <td>{{ row.shop.distributor }}</td>
                <td>{{ row.date }}</td>
                <td v-for="(header, idx) in prodHeaders" :key="idx">
                  {{ data[index][header] }}
                </td>
                <td>{{ row.amount }}</td>
              </template>
            </base-table>
          </div>
          <div v-else class="d-flex justify-content-center">
            <lottie-player
              src="https://assets1.lottiefiles.com/packages/lf20_lwnuxmxm.json"
              background="transparent"
              speed="1"
              style="width: 300px; height: 300px"
              loop
              autoplay
            ></lottie-player>
          </div>
        </card>
      </v-app>
    </div>
  </div>
</template>
<script>
export default {
  name: "staff-report",
  data() {
    return {
      tableHeaders: [
        "Staff",
        "Shop Name",
        "Shop Location",
        "Distributor",
        "Date",
        "Amount",
      ],
      maxDisplay: 2,
      pagedTableData: [],
      searchData: {
        asm: [],
        from_date: null,
        to_date: null,
      },
      filteredData: [],
      filteredHeaders: [],
      headers: [
        "Staff",
        "Shop Name",
        "Shop Location",
        "Distributor",
        "Date",
        "Amount",
      ],
      prodHeaders: [],
    };
  },
  methods: {
    filterReport() {
      let error = undefined;

      if (this.searchData.asm === null) {
        error = "Please select a asm";
      }

      let asmID = this.searchData.asm.map((item) => item.id);

      console.log(asmID);
      let from_date = this.searchData.from_date;
      let to_date = this.searchData.to_date;

      if (from_date === "") {
        from_date = null;
      }
      if (to_date === "") {
        to_date = null;
      }

      if (error) {
        this.$notify({
          title: error,
          icon: "fa fa-exclamation-triangle",
          type: "danger",
        });
      } else {
        let payload = {
          asms: [...asmID],
          from_date,
          to_date,
        };
        this.$store.dispatch("fetchASMReport", { payload }).catch((err) => {
          this.$notify({
            title: "Failed to fetch asm remark report",
            icon: "fa fa-exclamation-triangle",
            type: "danger",
            message: err.toString(),
          });
        });
      }
    },
    fetchAsmList() {
      this.$store.dispatch("fetchAsmList").catch((err) => {
        this.$notify({
          title: "Failed to fetch asm list",
          icon: "fa fa-exclamation-triangle",
          type: "danger",
          message: err.toString(),
        });
      });
    },
    toggleASMSelect() {
      this.$nextTick(() => {
        if (this.selectAllASM) {
          this.searchData.asm = [];
        } else {
          this.searchData.asm = this.asmList.slice();
        }
      });
    },
  },
  computed: {
    asmList() {
      return this.$store.state.asmStore.asmList;
    },
    asmReport() {
      return this.$store.state.reportStore.asmReport;
    },
    selectAllASM() {
      return this.searchData.asm.length === this.asmList.length;
    },
    selectSomeASM() {
      return this.searchData.asm.length > 0 && !this.selectAllASM;
    },
    iconASM() {
      if (this.selectAllASM) return "mdi-close-box";
      if (this.selectSomeASM) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    asmReport() {
      let products = [];
      this.asmReport.forEach((item) => {
        item.products.forEach((prod) => products.push(prod.product_name));
      });
      let filteredProd = [...new Set(products)];
      let report = [...this.asmReport];

      filteredProd.forEach((item) => {
        report.forEach((order, idx) => {
          order.products.forEach((prod) => {
            if (prod.product_name === item) {
              let productprice =
                Number(prod.total_price) / Number(prod.quantity);
              report[idx][
                prod.product_name
              ] = `${prod.quantity} x ${productprice} = ${prod.total_price}`;
            }
          });
        });
      });
      this.headers = [
        "Staff",
        "Shop Name",
        "Shop Location",
        "Distributor",
        "Date",
      ];
      this.headers = [...this.headers, ...filteredProd, "Total Amount"];
      this.prodHeaders = [...filteredProd];
      this.data = [...report];
      let tempdata = [...report];
      tempdata = tempdata.map((item) => ({
        staff: item.user.username,
        shop_name: item.shop.name,
        shop_place: item.shop.place,
        distributor: item.shop.distributor,
        date: item.date,
        ...item,
      }));
      tempdata.forEach((item) => {
        delete item.products;
        delete item.edited_date;
        delete item.gst;
        delete item.id;
        delete item.latitude;
        delete item.longitude;
        delete item.shop;
        delete item.shop_mobile;
        delete item.user;
      });
      this.filteredData = tempdata;
      let data1 = [];
      tempdata.forEach((item) => {
        data1.push(...Object.keys(item));
      });
      let data2 = [...new Set(data1)];
      let data3 = data2.map((e) => [e, e]);
      data3 = Object.fromEntries(data3);
      data3.total_amount = data3.amount;
      delete data3.amount;
      this.filteredHeaders = data3;
    },
  },
  mounted() {
    this.fetchAsmList();
  },
};
</script>
<style></style>
